import React, { useState } from 'react';
import './projects.scss';
import { RouteComponentProps } from '@reach/router';
import Project from '../../components/Project/Project';

import {
  AllProjects,
  AllRolesInProject,
  TechnologiesInProject,
} from './projectsList';
import ProjectFilter from '../../components/ProjectSearch/ProjectFilter';

interface ProjectsProps extends RouteComponentProps {}

export interface FilterInterface {
  name: string;
}

interface ProjectsState {
  filters: FilterInterface[];
}

const initialState: ProjectsState = {
  filters: [],
};

const Projects: React.FunctionComponent<ProjectsProps> = () => {
  const SetFilter = (inputFilters: FilterInterface[]) => {
    setState({ filters: inputFilters });
  };

  //Setting up state
  const [state, setState] = useState(() => {
    return initialState;
  });

  return (
    <section className="projectsPage">
      <h1 className="pageTitle">Projects</h1>
      <p className="pageSubheading">Personal Projects / Professional work</p>
      <ProjectFilter
        SetFilter={SetFilter}
        Filters={state.filters}
      ></ProjectFilter>
      {AllProjects.map((project, i) => {
        let passedFilter: boolean = false;
        let passedAllFilters: boolean[] = [];
        if (state.filters.length == 0) {
          passedFilter = true;
        } else {
          state.filters.forEach((filter, i) => {
            passedAllFilters[i] = false;
            project.technologies.forEach(tech => {
              if (tech.name.trim() == filter.name.trim()) {
                passedAllFilters[i] = true;
              }
            });
            project.roles.forEach(role => {
              if (role.name.trim() == filter.name.trim()) {
                passedAllFilters[i] = true;
              }
            });
          });
          passedFilter = true;
          passedAllFilters.forEach(passed => {
            if (passed == false) {
              passedFilter = false;
            }
          });
        }

        return passedFilter ? (
          <Project
            title={project.title}
            date={project.date}
            sourceLink={project.sourceLink}
            demoLink={project.demoLink}
            description={project.description}
            roles={project.roles}
            image={project.image}
            technologies={project.technologies}
            key={i} 
          ></Project>
        ) : null;
      })}
    </section>
  );
};

export default Projects;
