import React from 'react';
import './Navigation.scss';
// @ts-ignore
import img from '../../assets/i/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCoffee,
  faCode,
  faPaperPlane,
  faAddressCard,
  faBriefcase,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from '@reach/router';

interface NavigationState {
  mobileNavOpen: boolean;
}

const initialState : NavigationState = {
  mobileNavOpen: false
}

const NavigationComponent: React.FunctionComponent = () => {

  const toggleMobileNav = () => {

    setState({...state, mobileNavOpen: !state.mobileNavOpen});

  }
  const [state, setState] = React.useState(() => {
    return initialState;
  });
  
  return (
    <>
    <div className="navigationSpacer"></div>
    <div className="navigation">
      <img className="navigationLogo" src={img} />
      <NavigationItem text="Home" faIcon={faCoffee} path="/" />
      <NavigationItem text="About" faIcon={faAddressCard} path="about" />
      <NavigationItem text="Work Experience" faIcon={faBriefcase} path="workexperience" />
      <NavigationItem text="Projects" faIcon={faCode} path="projects" />
      <NavigationItem text="Contact" faIcon={faPaperPlane} path="contact" />
      <div className={`navigationMobileButton${state.mobileNavOpen ? " active": ""}`} onClick={() => {toggleMobileNav()}}>

        <div></div>

      </div>
    </div>
    <div className={`mobileNavigation${state.mobileNavOpen ? " active": ""}`}>
      <MobileNavigationItem text="Home" faIcon={faCoffee} path="/" closeFunction={toggleMobileNav}/>
      <MobileNavigationItem text="About" faIcon={faAddressCard} path="about" closeFunction={toggleMobileNav}/>
      <MobileNavigationItem text="Work Experience" faIcon={faBriefcase} path="workexperience" closeFunction={toggleMobileNav}/>
      <MobileNavigationItem text="Projects" faIcon={faCode} path="projects" closeFunction={toggleMobileNav}/>
      <MobileNavigationItem text="Contact" faIcon={faPaperPlane} path="contact" closeFunction={toggleMobileNav}/>
      <p className="mobileNavigationQuote">"There are only two hard problems in Computer Science: cache invalidation and naming things"<br/><span>- Phil Karlton</span></p>
    </div>
    </>
  );
};

interface NavigationItemProps {
  faIcon: IconProp;
  text: string;
  path: string;
}

const NavigationItem: React.FunctionComponent<NavigationItemProps> = ({
  faIcon,
  text,
  path
}: NavigationItemProps) => {
  return (
    <Link to={path}>
      <div className="navItem">
        <FontAwesomeIcon icon={faIcon} />
        <p>{text}</p>
      </div>
    </Link>
  );
};

interface MobileNavigationItemProps {
  faIcon: IconProp;
  text: string;
  path: string;
  closeFunction(): void;
}
const MobileNavigationItem: React.FunctionComponent<MobileNavigationItemProps> = ({
  faIcon,
  text,
  path,
  closeFunction
}: MobileNavigationItemProps) => {
  return (
    <Link to={path}>
      <div className="mobileNavItem" onClick={()=>{closeFunction()}}>
        <FontAwesomeIcon icon={faIcon} />
        <p>{text}</p>
      </div>
    </Link>
  );
};
export default NavigationComponent;
