import React from 'react';
import './contact.scss';
import { RouteComponentProps } from '@reach/router';
import axios from 'axios';

interface ContactProps extends RouteComponentProps { }

interface ContactState {
  name: string;
  email: string;
  message: string;
  submitButtonText: string;
  submitButtonClass: string;
  phone: string;
}

const initialState: ContactState = {
  name: "",
  email: "",
  message: "",
  phone: "",
  submitButtonText: "Submit",
  submitButtonClass: "submittable"
  //submitting
  //submitted
  //submitError
}

const Contact: React.FunctionComponent<ContactProps> = () => {
  const submitForm = (e: any) => {
    e.preventDefault();
    if(state.email == "") {
      alert("Email can't be empty.");
      return;
    }
    if (state.name == "") {
      alert("Name can't be empty.");
      return;
    }
    if(state.message == "") {
      alert("Message can't be empty.");
      return;
    }
    if (state.submitButtonClass == "submittable" || state.submitButtonClass === "submitted") {
      setState({ ...state, submitButtonText: "Submitting", submitButtonClass: "submitting" })
      axios({
        method: 'post',
        url: "https://api.joshuaroelle.com",
        headers: {
          contentType: "application/json"
        },
        data: {
          type: "email",
          heading: "Portfolio Form Submition",
          name: state.name,
          email: `${state.email}, ${state.phone}`,
          message: state.message
        }
      }).then((response) => {
        if (response.status == 200) {
          setState({ ...state, 
            name: "",
            email: "",
            phone: "",
            message: "",
            submitButtonText: "Submitted", 
            submitButtonClass: "submitted"
          
          })
        }

      }).catch(error => {
        console.log(error)
        setState({ ...state, submitButtonText: "Erorr", submitButtonClass: "submitError" })
      });
    }
  }

  const [state, setState] = React.useState(() => {
    return initialState;
  });

  return (
    <section className="contactPage">
      <h1 className="pageTitle">Contact Me</h1>
      <p className="pageSubheading">Professional Inqueries/Messages</p>

      <p className="contactPageMessage">
        Feel free to send me an email at{' '}
        <span className="highlightedText">dev@joshuaroelle.com</span>! I'll
        get back to you as soon as I can.{' '}
      </p>
      {/* <form className="contactPageForm" action="">
        <label className="contactPageLabel">Name/Company</label>
        <input
          type="text"
          name="name"
          placeholder="Enter Name"
          value={state.name}
          onChange={e => {
            setState({ ...state, name: e.target.value });
          }}

        />
        <label className="contactPageLabel">Email Address</label>
        <input
          value={state.email}
          type="text"
          name="email"
          placeholder="johndoe@gmail.com"
          onChange={e => {
            setState({ ...state, email: e.target.value });
          }}
        />
        <label className="contactPageLabel">Phone Number</label>
        <input
          value={state.phone}
          name="phone"
          type="text"
          placeholder="(123) 456 7890"
          onChange={e => {
            setState({ ...state, phone: e.target.value });
          }}
        />
        <label className="contactPageLabel">Message</label>
        <textarea
          name="message"
          value={state.message}
          placeholder="Enter Message"
          onChange={e => {
            setState({ ...state, message: e.target.value });
          }}
        />
        <button className={state.submitButtonClass} onClick={(e) => submitForm(e)}>{state.submitButtonText}</button>
      </form> */}
    </section>
  );
};

export default Contact;
