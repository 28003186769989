import React from 'react';
import { RouteComponentProps } from '@reach/router';
import './home.scss';


interface HomeProps extends RouteComponentProps {}



const Home: React.FunctionComponent<HomeProps> = () => {



  return (
    <section className="homePage">
      
    </section>
  );
};

export default Home;
