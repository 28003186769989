import React from 'react';
import './work.scss';
import { RouteComponentProps, Link } from '@reach/router';

interface WorkExperienceProps extends RouteComponentProps { }

const WorkExperience: React.FunctionComponent<WorkExperienceProps> = () => {

  return (
    <section className="workPage">
      <h1 className="pageTitle">Work Experience</h1>
      <p className="pageSubheading">Past Jobs/Contracts</p>
      <div className="workPageBlock">
        <h4 className="workPageBlockTitle">Blaze.me</h4>
        <p className="workPageBlockSubTitle">Jan 2020 - Current | Senior Software Engineer</p>
        <ul className="workPageBlockList">
          <li>Collaborated with, trained, and instructed other developers that I worked with.</li>
          <li>Helped architect, implement, and maintain various projects within the company using technologies such as <b>Java</b>, <b>React</b>, <b>ReactNative</b>, and <b>MongoDB</b>. </li>
          <li>Mentored junior developers, performed code reviews, and provided feedback to ensure high quality code delivery</li>
        </ul>
      </div>
      <div className="workPageBlock">
        <h4 className="workPageBlockTitle">Craftstones</h4>
        <p className="workPageBlockSubTitle">July 2019 - Dec 2019 | Developer</p>
        <ul className="workPageBlockList">
          <li>Moved their inventory, accounting, contacts, accounts, and more onto a cloud based ERP Solution. </li>
          <li>Developed <b>C#</b> scripts to connect to their old ERP systems then using object mapping and cleanup scripts to translate the data over to the new system. </li>
          <li>Developed and maintained an ecommerce solution for the company. Increasing sales by a quarter million. </li>
          <li>Worked with their ERP system and eCommerce website using technologies like <b>php</b>, <b>MySQL</b>, <b>CSS</b>, <b>Twig</b>, and the system's Rest API.</li>
        </ul>
      </div>
      <div className="workPageBlock">
        <h4 className="workPageBlockTitle">RV Auto Parts</h4>
        <p className="workPageBlockSubTitle">Mar 2019 - Jul 2019 | .Net Developer Contract</p>
        <ul className="workPageBlockList">
          <li>Developed and maintained an ETL system to move product data over from multiple suppliers using various APIs to a common format. Then updated listing on multiple sales channels with this data.</li>
          <li>Worked with REST APIs to gather data.</li>
          <li>Worked with <b>Entity Framework</b> to communicate and store product data.</li>
          <li>Sent requests out to update product listings on various sales channels such as Amazon, Ebay, and 3dCart.</li>
          <li>Developed a front end to display the translated data using <b>ReactJS</b>, <b>Typescript</b>, <b>SCSS</b>, and other technologies.</li>
          <li>Developed a backend to supply data to the frontend using <b>ASP.Net</b>.</li>
        </ul>
      </div>
      <div className="workPageBlock">
        <h4 className="workPageBlockTitle">K&R Web Design</h4>
        <p className="workPageBlockSubTitle">Dec 2017 - Feb 2019 | Freelance Web Development</p>
        <ul className="workPageBlockList">
          <li>Built websites from PSD or Adobe XD based designs.</li>
          <li>Worked with clients to find what they would like aswell as explaining the technical process.</li>
          <li>Built APIs using ASP.Net or ExpressJS for clients.</li>
          <li>Worked with various relational databases for various reasons.</li>
          <li>Communicated with 3rd party APIs to retrieve data.</li>
          <li>Developed and helped improve prexisting apps, and websites.</li>
          <li>Collaborated in development with  other programmers, aswell as designers to create a product that met a client's needs.</li>
        </ul>
      </div>
    </section>
  )
}
export default WorkExperience;