import { hot } from 'react-hot-loader/root';
import * as React from 'react';
import { AppRouter } from './router';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons';
import NavigationComponent from '../components/Navigation/Navigation';
import CodeHighligher from '../components/CodeHighlighter/CodeHighlighter';
import './App.scss';
import ContentBlock from '../components/ContentBlock/ContentBlock';
import { Location } from '@reach/router';
import '../globals/staticGlobalStyling.scss'

library.add(fab, faCheckSquare, faCoffee);

const App = () => (
  <div className="applicationHome">
    <NavigationComponent></NavigationComponent>

    <Location>
      {({ location }) => (
        <>
          <CodeHighligher blurred={location.pathname == '/' ? false : true}></CodeHighligher>
          <ContentBlock active={location.pathname == '/' ? false : true}>
            <AppRouter />
          </ContentBlock>
        </>
      )}
    </Location>
  </div>
);

/* ts complaining over having it directly in the app decl. */
export default hot(App);
