import React from 'react';
import './ProjectFilter.scss';
import {
  TechnologiesInProject,
  AllRolesInProject,
} from '../../pages/projects/projectsList';
import { FilterInterface } from '../../pages/projects/projects';
import { faRuler } from '@fortawesome/free-solid-svg-icons';

interface ProjectFilterProps {
  SetFilter: (inputFilters: FilterInterface[]) => void;
  Filters: FilterInterface[];
}

const ProjectFilter: React.FunctionComponent<ProjectFilterProps> = ({
  SetFilter,
  Filters,
}: ProjectFilterProps) => {
  const removeFilter = (inputFilter: FilterInterface) => {
    console.log(inputFilter);
    console.log(Filters[0].name == inputFilter.name);
    let inputIndex : number = -1;
     Filters.map((filter, i) => {
      if(filter.name == inputFilter.name) {
        inputIndex = i;
      }
    });

    if (inputIndex == -1) {
      console.log('ERROR index was -1 on Filter removal');
      return;
    }
    Filters.splice(inputIndex, 1);
    SetFilter(Filters);
  };

  const addFilter = (inputFilter: FilterInterface) => {
    Filters.push(inputFilter);
    SetFilter(Filters);
  };

  const getAllFilters = () => {
    let output: FilterInterface[];
    //Place all roles and techs into output
    let roles = Object.values(AllRolesInProject);
    let techs = Object.values(TechnologiesInProject);
    output = roles.map((role: FilterInterface) => {
      return { name: role.name };
    });
    output = output.concat(
      techs.map((tech: FilterInterface) => {
        return { name: tech.name };
      })
    );

    return output;
  };

  return (
    <div className="ProjectFilterContainer">
      <p className="ProjectFilterHeading">Filters</p>
      <div className="ProjectFilterFilterContainer">
        {getAllFilters().map((filter, i) => {
          let activeFilter = false;
          //Doesn't match old filter
          activeFilter =  (Filters.find(activeFilter => {
            if(activeFilter.name == filter.name) {
              return true;
            }
          }) != undefined)
          if (activeFilter) {
            return (
              <div
                className="ProjectFilterFilter active"
                key={i}
                onClick={() => {
                  removeFilter(filter);
                }}
              >
                <span className="ProjectFilterFilterDelete">-</span>
                {filter.name}
              </div>
            )
          }
          else {
            return (
              <div
                className="ProjectFilterFilter"
                key={i}
                onClick={() => {
                  addFilter(filter);
                }}
              >
                <span className="ProjectFilterFilterDelete">+</span>
                {filter.name}
              </div>
            )
          }
        })}
      </div>
    </div>
  );
};
export default ProjectFilter;
