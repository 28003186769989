import * as React from 'react';
import { Router } from '@reach/router';
const WorkExperience = React.lazy(()=>import('../pages/work/work'));
const Home = React.lazy(()=>import('../pages/home/home'));
const Projects = React.lazy(()=>import('../pages/projects/projects'));
const Contact = React.lazy(()=>import('../pages/contact/contact'));
const About = React.lazy(()=>import('../pages/about/about'));
const NotFound = React.lazy(()=>import('../components/404/404'));

export const AppRouter = () => {
  return (
    <>
      <React.Suspense fallback={<div>Loading...</div>}>
        <Router>
          <Home path="/"/>
          <Projects path="/projects"/>
          <About path="/about"/>
          <Contact path="/contact"/>
          <WorkExperience path="/workexperience"/>
          <NotFound default />
        </Router>
      </React.Suspense>
    </>
  );
};
