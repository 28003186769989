import React from 'react';
import './Project.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons';

import { faGithub } from '@fortawesome/free-brands-svg-icons';
import {
  RoleInterface,
  TechnologyInterface,
} from '../../pages/projects/projectsList';

interface ProjectProps {
  title: string;
  date: string;
  sourceLink?: string;
  demoLink?: string;
  description: string;
  roles: RoleInterface[];
  image: any;
  technologies: TechnologyInterface[];
}

const Project: React.FunctionComponent<ProjectProps> = ({
  title,
  date,
  sourceLink,
  demoLink,
  description,
  roles,
  image,
  technologies,
}: ProjectProps) => {
  return (
    <div className="project">
      <h4 className="projectHeading">{title}</h4>
      <p className="projectDate">{date}</p>
      <div className="projectLinks">
        {demoLink ? (
          <a target="_blank" href={demoLink} className="projectDemo">
            <FontAwesomeIcon icon={faCode} /> Demo
          </a>
        ) : (
          <a className="projectDemo Invalid">
            <FontAwesomeIcon icon={faCode} /> Demo
          </a>
        )}
        {sourceLink ? (
          <a target="_blank" href={sourceLink} className="projectSource">
            <FontAwesomeIcon icon={faGithub} /> Source
          </a>
        ) : (
          <a className="projectSource Invalid">
            <FontAwesomeIcon icon={faGithub} /> Source
          </a>
        )}
      </div>
      <div className="projectImageDescContainer">
        <div className="projectImageContainer">
          <img className="projectImage" src={image} />
        </div>

        <div className="projectTextContainer">
          <p className="projectDescription">{description}</p>
          <ul className="projectTechnologies">
            <li className="projectTechnologyHeading">Technologies</li>
            {technologies.map((tech, i) => {
              return (
                <li className="projectTechnology" key={i}>
                  <img
                    className="projectTechnologyImage"
                    src={tech.image}
                  ></img>
                  <p className="projectTechnologyName">{tech.name}</p>
                </li>
              );
            })}
          </ul>
          <p className="projectRoles">
            Role{roles.length > 1 ? 's' : null}:{' '}
            {roles.map((role, i , roles) => {
              if(i+1 == roles.length) {
                return role.name;
              }
              return role.name+", "
            })}
          </p>
        </div>
      </div>
    </div>
  );
};
export default Project;
