import React from 'react';
import './about.scss';
import { RouteComponentProps, Link } from '@reach/router';

//Tech Images
// @ts-ignore
import JSImage from '../../assets/i/techs/js.png';
// @ts-ignore
import TSImage from '../../assets/i/techs/ts.png';
// @ts-ignore
import pixiJSImage from '../../assets/i/techs/pixijs.png';
// @ts-ignore
import cSharpImage from '../../assets/i/techs/csharp.png';
// @ts-ignore
import reactImage from '../../assets/i/techs/react.png';
// @ts-ignore
import reduxImage from '../../assets/i/techs/redux.png';
// @ts-ignore
import sassImage from '../../assets/i/techs/sass.png';

//Collab Images
// @ts-ignore
import discordImage from '../../assets/i/collaborationTools/discord.png';
// @ts-ignore
import gitImage from '../../assets/i/collaborationTools/git.png';
// @ts-ignore
import githubImage from '../../assets/i/collaborationTools/github.png';
// @ts-ignore
import mondayImage from '../../assets/i/collaborationTools/monday.png';
// @ts-ignore
import slackImage from '../../assets/i/collaborationTools/slack.png';

interface AboutProps extends RouteComponentProps {}

const About: React.FunctionComponent<AboutProps> = () => {
  return (
    <section className="aboutPage">
      <h1 className="pageTitle">About me</h1>
      <p className="pageSubheading">Information/Resume</p>
      <div className="aboutPageBlock">
        <div className="aboutPageMainContent">
          <h4 className="aboutPageBlockTitle">Intro</h4>
          <p>
            Hello! I'm Josh I am a Developer with over 4 years of experience
            in developing <span className="highlightedText">Front-Ends</span>,{' '}
            <span className="highlightedText">Back-Ends</span>,{' '}
            <span className="highlightedText">Apps</span>,{' '}
            <span className="highlightedText">Databases</span>, and{' '}
            <span className="highlightedText">more</span> for clients as well as
            personal projects.
          </p>
          <p>
            I have a passion for technology, innovation, critical thinking, and
            most of all programming. I appreciate working through and taking
            care of complex issues like the ones that frequently emerge in
            programming.
          </p>
        </div>
        <div className="aboutPageSideContent">
          <div className="aboutPageIntroSide">
            <h5 className="aboutPageSubHeading">Work:</h5>
            
            <h6 className="aboutPageSubHeading">
              <b>Blaze.me</b>
            </h6>
            <h6>Jan 2020 - Current</h6>
            <h6>-Senior Software Engineer</h6>

            <h6 className="aboutPageSubHeading">
              <b>Craftstones</b>
            </h6>
            <h6>July 2019 - Dec 2019</h6>
            <h6>-Web Developer</h6>

            <h6 className="aboutPageSubHeading">
              <b>RV AutoParts</b>
            </h6>
            <h6>March 2019 - June 2019</h6>
            <h6>-.Net Developer Contract</h6>
            
            <h6 className="aboutPageSubHeading">
              <b>K&R Web Desgins</b>
            </h6>
            <h6>-December 2017- February 2019</h6>
            <h6>-Front End Developer</h6>
            <h6>
              <Link to="/workexperience">See Work Details {"->"}</Link>
            </h6>
            <h5 className="aboutPageSubHeading">
              <b>Past Projects:</b>
            </h5>
            <h6>
              <Link to="/projects">{"Ses My Projects ->"}</Link>
            </h6>
          </div>
        </div>
      </div>

      <div className="aboutPageBlock">
        <div className="aboutPageMainContent">
          <h4 className="aboutPageBlockTitle">What I've Learned</h4>
          <p>
            From early on in high school, I took an interest in how to program,
            I began by teaching myself front-end technologies, and attending
            courses related to development. Utilizing these skills, I began
            building websites for businesses in my area. Over time, that taught
            me valuable technical skills aswell as interpersonal skills by
            interacting, proposing, and dealing with clients.
          </p>
          <p>
            In the years following, I accomplished more advanced development,
            getting by into back-ends, databases, graphics, and some game
            development. As a result of this, I got involved in different
            libraries, patterns, and languages.
          </p>
          <p>
            I have a enthusiasm for continuing to learn and grow as a developer.
            Keeping with the times of the ever changing industry is something I
            enjoy and have an aptitude for doing.
          </p>
        </div>

        <div className="aboutPageSideContent">
          <div className="aboutPageImageList">
            <h4>Technologies I Use Regularly</h4>
            <ul>
              <li>
                <img src={JSImage} />
                <p>Javascript</p>
              </li>
              <li>
                <img src={TSImage} />
                <p>TypeScript</p>
              </li>
              <li>
                <img src={reactImage} />
                <p>React Native</p>
              </li>
              <li>
                <img src={cSharpImage} />
                <p>C#</p>
              </li>
              <li>
                <img src={reactImage} />
                <p>React</p>
              </li>
              <li>
                <img src={reduxImage} />
                <p>Redux</p>
              </li>
              <li>
                <img src={sassImage} />
                <p>SCSS</p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="aboutPageBlock">
        <h4 className="aboutPageBlockTitle">Who Knows Me</h4>
        <div className="aboutPageReference">
          <div className="aboutPageReferenceInfo">
            <h6>Cantel Birminghim</h6>
            <p>760.522.6617</p>
            <p>cantel.birmingham@yahoo.com</p>
          </div>
          <div className="aboutPageReferenceQuote">
            <p>
              "I have the pleasure of knowing Josh personally and
              professionally. I find Josh reliable and one that is happy to go
              above and beyond any request. Some of the helpful things he has
              accomplished are setting up a home business network to building a
              computer. He is willing to take on any undertaking that is needed,
              regardless of whether or not requested to do as such."
            </p>
          </div>
        </div>
        <div className="aboutPageReference">
          <div className="aboutPageReferenceInfo">
            <h6>Benjamin Klingner</h6>
            <p>760.703.3222</p>
            <p>bklingner@ksrepublic.net</p>
          </div>
          <div className="aboutPageReferenceQuote">
            <p>
              I have worked with Josh over the course of the last few years on
              several projects. Josh brings a positive energy into any work
              environment he was a part of. Josh works professionally, remains
              on schedule, and maintains great open communication with others on
              a team.
            </p>
          </div>
        </div>
      </div>

      <div className="aboutPageBlock">
        <div className="aboutPageMainContent">
          <h4 className="aboutPageBlockTitle">
            Why I Might Be a Good Addition
          </h4>
          <p>
            Throughout my career I have learned that excellent communication is
            a must have skill. It is the keystone of an enjoyable and productive
            work environment. Without communication project deadlines can be
            missed, problems may be overlooked, and productivity may fall.
          </p>
          <p>
            I would be a asset due to my ability to work independently, or as
            part of a team. I can lead as well as follow.
          </p>
        </div>

        <div className="aboutPageSideContent">
          <div className="aboutPageImageList">
            <h4 className="aboutPageBlockTitle">
              Collaboration Systems I Already Use
            </h4>
            <ul>
              <li>
                <img src={githubImage} />
                <p>github</p>
              </li>
              <li>
                <img src={gitImage} />
                <p>git</p>
              </li>
              <li>
                <img src={discordImage} />
                <p>Discord</p>
              </li>
              <li>
                <img src={slackImage} />
                <p>Slack</p>
              </li>
              <li>
                <img src={mondayImage} />
                <p>Monday.com</p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="aboutPageBlock aboutPageBlockContact">
        <h5>Still There?</h5>
        <h4>Great!</h4>
        <p>
          I'm available to answer any question or address any concerns you may
          have. <br /> Head over to my contact page and lets get in touch!
        </p>
        <Link to="/contact">{'Contact Me ->'}</Link>
      </div>
    </section>
  );
};

export default About;
