//TechnologyImages
// @ts-ignore
import JSImage from '../../assets/i/techs/js.png';
// @ts-ignore
import TSImage from '../../assets/i/techs/ts.png';
// @ts-ignore
import pixiJSImage from '../../assets/i/techs/pixijs.png';
// @ts-ignore
import cSharpImage from '../../assets/i/techs/csharp.png';
// @ts-ignore
import reactImage from '../../assets/i/techs/react.png';
// @ts-ignore
import reduxImage from '../../assets/i/techs/redux.png';
// @ts-ignore
import sassImage from '../../assets/i/techs/sass.png';



export interface TechnologyInterface {
  name: string;
  image: any;
}

export interface ProjectInterafce {
  title: string;
  date: string;
  sourceLink?: string;
  demoLink?: string;
  description: string;
  roles: RoleInterface[];
  image: any;
  technologies: TechnologyInterface[];
}
interface TechnologiesInterface {
  js: TechnologyInterface;
  ts: TechnologyInterface;
  scss: TechnologyInterface;
  pixijs: TechnologyInterface;
  react: TechnologyInterface;
  redux: TechnologyInterface;
  ef: TechnologyInterface;
  csharp: TechnologyInterface;
}
export const TechnologiesInProject: TechnologiesInterface = {
  js: { name: 'JavaScript', image: JSImage },
  ts: { name: 'TypeScript', image: TSImage },
  scss: { name: 'SCSS', image: sassImage },
  pixijs: { name: 'PIXI.js', image: pixiJSImage },
  react: { name: 'ReactJS', image: reactImage },
  redux: { name: 'Redux', image: reduxImage },
  ef: { name: 'Entity Framework (Core/Not Core)', image: cSharpImage },
  csharp: { name: 'C#', image: cSharpImage },
};
export interface RoleInterface {
  name: string;
}
interface AllRolesInterface {
  frontEnd: RoleInterface
  backEnd: RoleInterface,
}
export const AllRolesInProject : AllRolesInterface = {
  frontEnd: { name: "Front-End Development"},
  backEnd: {name: "Back-End Development"}
}

//ProjectPictures
// @ts-ignore
import HexagonGridImage from '../../assets/i/HexagonGridPic.png';
// @ts-ignore
import ReactReduxBoilerplate from '../../assets/i/ReactReduxBoilerplatePic.png';
// @ts-ignore
import Poway from '../../assets/i/poway.jpg';
// @ts-ignore
import PowayDays from '../../assets/i/PowayDays.jpg';
// @ts-ignore
import Acme from '../../assets/i/Acme.jpg';

export const AllProjects: ProjectInterafce[] = [
  {
    title: 'React/Redux Boilerplate',
    date: 'August 2019-September 2019',
    sourceLink: 'https://github.com/Joshykins/react-redux-ts-scss-boilerplate',
    description:
      'This is a react boilerplate using technologies that I like to use throughout my projects. It includes ParcelJS for loading, Redux for state management, React-Router for routing, SCSS styling, and an example component using actions, reducers, and middleware.',
    roles: [AllRolesInProject.frontEnd],
    image: ReactReduxBoilerplate,
    technologies: [
      TechnologiesInProject.ts,
      TechnologiesInProject.scss,
      TechnologiesInProject.react,
      TechnologiesInProject.redux,
    ],
  },
  {
    title: 'WebGL/PIXI JS Hexagon Renderer',
    date: 'March 2019',
    sourceLink: 'https://github.com/Joshykins/Hexagon-Grid',
    demoLink: 'https://codepen.io/Joshowaa/pen/YgrWZa',
    description:
      "A Pixi.Js powered hexagon background that uses perlin noise in to generate live backgrounds. It has a live-updating settings that can dramaticly change it's appearance.",
    roles: [AllRolesInProject.frontEnd],
    image: HexagonGridImage,
    technologies: [ TechnologiesInProject.js,  TechnologiesInProject.pixijs,  TechnologiesInProject.scss],
  },
  {
    title: 'Poway Days',
    date: 'July 2018',
    description:
    "This project was a festival website with functionallity to have vendors and sponsors being able to sign up on the website as well as a CMS.",
    roles: [AllRolesInProject.frontEnd, AllRolesInProject.backEnd],
    image: PowayDays,
    technologies: [TechnologiesInProject.js, TechnologiesInProject.scss, TechnologiesInProject.csharp, TechnologiesInProject.ef]
  },
  {
    title: 'ACME Air Conditioning & Heating Co, Inc.',
    date: 'June 2018',
    demoLink: "http://acmeairconditioningandheating.com",
    description:
    "This was a rather simple PSD to Web. Using a a static web server called harpjs I created the website. ",
    roles: [AllRolesInProject.frontEnd],
    image: Acme,
    technologies: [TechnologiesInProject.js, TechnologiesInProject.scss]
  },
  {
    title: 'Poway Chamber of Commerce',
    date: 'September 2017 - Decemter 2017',
    demoLink: "http://poway.com",
    description:
    "This was one of my first freelance projects I developed professionally. It uses CraftCMS aswell as Chamber Master. I had to restyle all the imported components from Chamber Master in order to fit the new design I was adhereing to.",
    roles: [AllRolesInProject.frontEnd],
    image: Poway,
    technologies: [TechnologiesInProject.js, TechnologiesInProject.scss]
  },
];
