import React, { ReactChildren } from 'react';
import './ContentBlock.scss';

interface ContentBlockProps {
  active: boolean;
  children: React.ReactNode;
}

const ContentBlock: React.FunctionComponent<ContentBlockProps> = ({
  active,
  children,
}: ContentBlockProps) => {
  let classList = 'contentBlock';
  if (active) {
    classList += ' active';
  }

  return <div className={classList}>{children}</div>;
};
export default ContentBlock;
