import React, { useState, useRef } from 'react';
import Prism from 'prismjs';
import 'prismjs/components/prism-scss';
import 'prismjs/components/prism-jsx';
import 'prismjs/components/prism-csharp';
import 'prismjs/components/prism-tsx';
import 'prismjs/components/prism-typescript';

//plugins
import 'prismjs/plugins/line-numbers/prism-line-numbers.min';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';

import 'prismjs/plugins/show-language/prism-show-language.min';

//Our css
import './CodeHighlighter.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
/*
 *
 *
 */

const langTS = "ts";
const langTSX = "tsx";
const langSCSS = "scss";
const langCS = "csharp";

interface CodeExample {
  sourceName: string;
  sourceLink: string;
  language: string;
  code: string;
}

const codes: CodeExample[] = [
  {
    sourceName: "multi-user-jwt-boilerplate",
    sourceLink: "https://github.com/Joshykins/multi-user-jwt-boilerplate",
    language: langCS,
    code: `  

public class ReAuthenticator : ErrorDto
{
    public AuthenticatedDto Authenticate(string inputToken)
    {
        //Verify token
        var tokenManager = new TokenManager();
        TokenContent tokenContent = tokenManager.VerifyToken(inputToken);

        //Check if verification worked
        if (tokenManager.Errored)
        {
            Errored = true;
            ErrorMessages.Add(tokenManager.ErrorMessage);
            return null;
        }

        //Refresh token
        string token = tokenManager.CreateToken(tokenContent.UserId);
        Authorization authorizationData;
        try
        {
            using (mulContext context = new mulContext())
            {
                Authorizer authorizer = new Authorizer();
                Users user = context.Users.FirstOrDefault(o => o.Id == tokenContent.UserId);
                authorizationData = authorizer.GetAuthorization(context, user);
            }
        }
        catch (Exception Ex)
        {
            Errored = true;
            ErrorMessages.Add(Ex.Message);
            return null;
        }

        var output = new AuthenticatedDto
        {
            Token = token,
            Authorized = authorizationData
        };

        return output;
    }
}
`,
  },
  {
    sourceName:"Portfolio",
    sourceLink: "",
    language: langSCSS,
    code: `
@import '../../globals/globalStyling.scss';

.project {
  width: 100%;
  padding: 40px 0px;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
  &:after {
    display: block;
    background-color: white;
    height: 1px;
    width: 100%;
    margin: 40px 0px;
    content: "";
  }
  &:last-of-type {
    &:after {
      display: none;
    }
  }
  &Heading {
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: center;
    display: block;
    color: white;
    font-family: $secondaryFontFamily;
    font-weight: 400;
    font-size: 1.3em;
    letter-spacing: 2px;
  }
  &Date {
    margin: 0px 0px;
    padding: 5px 0px;
    width: 100%;
    text-align: center;
    color: white;
    font-family: $secondaryFontFamily;
    font-weight: 300;
    font-size: 1em;
    letter-spacing: 2px;
  }
  &ImageDescContainer {
    display: flex;
    @media screen and (max-width: 1080px) {
      flex-wrap: wrap;
    }
  }
  &ImageContainer {
    position: relative;
    height: fit-content;
    width: calc(100% - 400px);
    @media screen and (max-width: 1080px) {
      width: 100%;
    }
  }
  

  &Image {
    width: 100%;
    height: 100%;
    padding: 0px 0px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  &TextContainer {
    width: 400px;
    @media screen and (max-width: 1080px) {
      width: 100%;
    }
  }
  &Technologies {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin: 0;
  }
  &Technology {
    height: 40px;
    display: flex;
    &Image {
      height: 25px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin-top: 7.5px;
      margin-bottom: 7.5px;
    }
}
`
  },
  {
    sourceName: "react-redux-ts-scss-boilerplate",
    sourceLink: "https://github.com/Joshykins/react-redux-ts-scss-boilerplate/blob/master/src/reducers/counterExample.ts",
    language: langTS,
    code: `
    import { CounterExampleActionsEnum, CounterExampleAction } from '../actions';

    const initialState = {
      name: 'Counting to infinity!',
      count: 0,
    };
    
    export const counterExampleReducer = (
      state = initialState,
      action: CounterExampleAction
    ) => {
      switch (action.type) {
        case CounterExampleActionsEnum.COUNTER_EXAMPLE_CHANGENAME: {
          return {
            ...state,
            name: action.payload,
          };
        }
        case CounterExampleActionsEnum.COUNTER_EXAMPLE_ADD: {
          return {
            ...state,
            count: state.count + 1,
          };
        }
        case CounterExampleActionsEnum.COUNTER_EXAMPLE_SUBTRACT: {
          return {
            ...state,
            count: state.count - 1,
          };
        }
        default:
          return state;
      }
    };

  `}
];

const starterComment: string = `
/*
 * This is just a background for the website, 
 * use the navigation to view the content of my website.
 */
`;

interface CodeHighligherState {
  codeHighlightSource: string;
  codeHighlightSourceLink: string;
  codeHighlightText: string;
  codeHighlighterLang: string;
  codeHiglightIndex: number;
  codeHighlightRenderCursor: boolean;
  lastScrolledHeight: number;
}

const startingPos = Math.floor(codes.length * Math.random());

const initialState: CodeHighligherState = {
  codeHighlightSource: codes[startingPos].sourceName,
  codeHighlightSourceLink: codes[startingPos].sourceLink,
  codeHighlightText: codes[startingPos].code,
  codeHighlighterLang: codes[startingPos].language,
  codeHiglightIndex: 0,
  codeHighlightRenderCursor: true,
  lastScrolledHeight: 0,
};

interface CodehighlighterProps {
  blurred: boolean;
}

const CodeHighligher: React.FunctionComponent<CodehighlighterProps> = ({
  blurred,
}: CodehighlighterProps) => {
  let scrollRef: any = React.createRef();

  const UpdateScroll = (ref: any) => {
    if (ref.current.scrollHeight - 80 > state.lastScrolledHeight) {
      ref.current.scrollTo({
        top: ref.current.scrollHeight,
        left: 0,
        behavior: 'smooth'
      });

      setState({ ...state, lastScrolledHeight: ref.current.scrollHeight });
    }
  };

  //Setting up highlight
  const UpdateCodeHighlightText = () => {
    if (state.codeHiglightIndex < state.codeHighlightText.length) {
      state.codeHiglightIndex += 1;

      setState({
        ...state,
        codeHiglightIndex: state.codeHiglightIndex,
      });
      Prism.highlightAll();
    } else {
      let newIndex = Math.floor(codes.length * Math.random());

      setState({
        ...state,
        codeHighlightSource: codes[newIndex].sourceName,
        codeHighlightSourceLink: codes[newIndex].sourceLink,
        codeHighlightText: codes[newIndex].code,
        codeHighlighterLang: codes[newIndex].language,
        codeHiglightIndex: 0,
        lastScrolledHeight: 0
      });
    }
  };

  //Setting up state
  const [state, setState] = useState(() => {
    return initialState;
  });

  React.useEffect(() => {
    if (state.codeHiglightIndex >= state.codeHighlightText.length) {
      var timer = setTimeout(() => {
        UpdateCodeHighlightText();
      }, 5000);
    } else {
      var timer = setTimeout(() => {
        UpdateCodeHighlightText();
      }, 10 + Math.random() * 3);
    }

    UpdateScroll(scrollRef);
    return () => {
      clearTimeout(timer);
    };
  }, [state.codeHiglightIndex, state.lastScrolledHeight]);


  React.useEffect(() => {

    let languageClass = `language-${state.codeHighlighterLang}`;
    if (blurred) {
      languageClass += ' blurred';
    }

  }, [state.codeHighlightText]);

  let languageClass = `language-${state.codeHighlighterLang}`;
  if (blurred) {
    languageClass += ' blurred';
  }

  return (
    <div className="codeTerminal" ref={scrollRef}>
      <div className={`codeTerminalLanguage ${blurred ? ` blurred` : null}`}>Source: <a href={state.codeHighlightSourceLink} target="_">{state.codeHighlightSource}</a> - {state.codeHighlighterLang}</div>
      <pre className="line-numbers">
        <code className={languageClass}>
          {starterComment +
            state.codeHighlightText.slice(0, state.codeHiglightIndex) +
            '|'}
        </code>
      </pre>
    </div>
  );
};

export default CodeHighligher;
